import Vue from "vue"
import { uploadByBase64 } from "@/api/file"
import dayjs from "dayjs"
// export function getFileById(id) {
//   return {
//     fileId: id,
//     filePath: getFilePathById(id),
//     fileName: `文件${id}`,
//   };
// }
export function getFilePathById(id) {
  return Vue.prototype.$rootUrl + `/File/Thumb/${id}`
}

export class PasteImg {
  _imgReader(item) {
    var blob = item.getAsFile(),
      reader = new FileReader()

    reader.onload = async (ev) => {
      // this.loading = true;
      try {
        let res = await uploadByBase64(ev.target.result)
        res.creationTime = dayjs().format("YYYY-MM-DD HH:mm:ss")
        res.creatorUserName = abp.session.user?.name
        if (this.option.after) {
          this.option.after(res)
        }
        // this.loading = false;
      } catch (e) {
        console.warn(e)
        // this.loading = false;
      }
    }

    reader.readAsDataURL(blob)
  }
  _callback(e) {
    if (this.option.pre) {
      let prevent = this.option.pre()
      if (prevent) return prevent
    }
    var clipboardData = e.clipboardData,
      i = 0,
      items,
      item,
      types

    if (clipboardData) {
      items = clipboardData.items

      if (!items) {
        return
      }

      item = items[0]
      types = clipboardData.types || []
      for (; i < types.length; i++) {
        if (types[i] === "Files") {
          item = items[i]
          break
        }
      }

      if (item && item.kind === "file" && item.type.match(/^image\//i)) {
        this._imgReader(item)
      }
    }
  }
  // 清除事件监听
  destroy() {
    document.removeEventListener("paste", this._callback)
  }
  constructor(option = {}) {
    this.option = option
    document.addEventListener("paste", (e) => this._callback(e))
    if (this.option.vm) {
      this.option.vm.$once("hook:beforeDestroy", this.destroy)
    }
  }
}

// 根据文件名判断类型
const defaultIcon = "mdi-file-document-outline"
const fileTypeMap = {
  image: {
    match: "bmp,jpg,png,tif,tiff,gif,pcx,tga,exif,fpx,svg,psd,cdr,pcd,dxf,ufo,eps,ai,raw,WMF,webp,jpeg,svg,smp,ico",
    icon: "mdi-file-image",
  },
  pdf: {
    match: "pdf",
    icon: "mdi-file-pdf",
  },
  zip: {
    match: "zip,gzip",
    icon: "mdi-zip-box",
  },
  excel: {
    match: "excal,xls,xlsx",
    icon: "mdi-file-excel",
  },
  doc: {
    match: "docx,doc",
    icon: "mdi-file-word",
  },
  json: {
    match: "json",
    icon: "mdi-code-json",
  },
  txt: {
    match: "txt",
    icon: "mdi-file-document-outline",
  },
  md: {
    match: "md",
    icon: "mdi-language-markdown",
  },
  html: {
    match: "html,htm",
    icon: "mdi-language-html5",
  },
  js: {
    match: "js,ts",
    icon: "mdi-nodejs",
  },
  cad: {
    match: "dwg,dwt,dxf,dws",
    icon: "mdi-file-cad",
  },
  video: {
    match: "mp4,avi,WAV,rm,rmvb,mpeg1,mpeg2,mpeg3,mpeg4,mov,mtv,dat,wmv,avi,3gp,amv,dmv,flv",
    icon: "mdi-file-cad",
  },
}
function normaliseName(file) {
  return typeof file === "object" ? file?.fileName || file?.filePath : file
}
const getFileType = function getFileType(file) {
  let name = normaliseName(file)
  name = name.replace(/.*\./, "") || ""
  let fileType = Object.keys(fileTypeMap).find((key) => {
    let map = fileTypeMap[key]
    return new RegExp(name).test(map.match)
  })
  return {
    type: fileType || null,
    icon: fileTypeMap[fileType]?.icon || defaultIcon,
  }
}
const getIconByFileName = function getIconByFileName(file) {
  return getFileType(file).icon
}

// 下载文件时重命名
function getBlob(url, cb) {
  // 获取文件流
  var xhr = new XMLHttpRequest()
  xhr.open("GET", url, true)
  xhr.responseType = "blob"
  xhr.onload = function () {
    if (xhr.status === 200) {
      cb(xhr.response)
    }
  }
  xhr.send()
}
function saveAs(blob, filename) {
  // 改名字
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename)
  } else {
    var link = document.createElement("a")
    var body = document.querySelector("body")

    link.href = window.URL.createObjectURL(blob)
    link.download = filename

    // fix Firefox
    link.style.display = "none"
    body.appendChild(link)

    link.click()
    body.removeChild(link)

    window.URL.revokeObjectURL(link.href)
  }
}
function download(url, filename) {
  return new Promise((resolve, _reject) => {
    // 执行
    getBlob(url, (blob) => {
      saveAs(blob, filename)
      resolve()
    })
  })
}
// 下载文件
const downLoadFile = function downLoadFile(url, name, effective = true) {
  var whiteList = ["image", "pdf", "json", "txt", "js", "html", "video"]
  let type = getFileType(name || url).type
  if (effective && whiteList.some((o) => o === type)) {
    window.open(url)
    return
  }
  name = name || url
  download(url, name || url) // OSS可下载的文件url，你想要改的名字
}
const downLoadFiles = function downLoadFiles(files, effective = false) {
  var whiteList = ["image", "pdf", "json", "txt", "md", "js", "html", "video"]
  return files.map(async (file) => {
    const { filePath: url, fileName: name } = file
    let type = getFileType(name || url).type
    if (effective && whiteList.some((o) => o === type)) {
      window.open(url)
    } else {
      await download(url, name || url) // OSS可下载的文件url，你想要改的名字
    }
  })
}

export { getFileType, getIconByFileName, downLoadFile, downLoadFiles }
