<!--  -->
<template>
  <el-link
    v-bind="$attrs"
    target="_blank"
    class="text-ellipsis link-filename"
    v-on="$listeners"
    @click="downLoadFile(file)"
  >
    <slot>
      {{ file.fileName }}
    </slot>
  </el-link>
</template>

<script>
import { downLoadFile } from "@/core/utils/file"
export default {
  props: { file: Object, openFile: Function },
  data() {
    return {}
  },
  methods: {
    downLoadFile(file) {
      if (this.openFile && typeof this.openFile === "function") {
        this.openFile(file)
      } else {
        downLoadFile(file.filePath, file.fileName)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.link-filename {
  line-height: 1.2em;
}
</style>
