export default {
  focus: {
    // 聚焦指令，用于组件中，打开就聚焦的情况
    // 当被绑定的元素更新到 DOM 中时……
    update: function (el, binding) {
      // 聚焦元素
      if (!binding.expression || binding.value) {
        if (el.nodeName !== "INPUT" || el.nodeName !== "TEXTAREA") {
          el = el.querySelector(".focus-input") || el.querySelector("input") || el.querySelector("textarea")
        }
        el.focus()
      }
    },
  },
}
