const files = require.context(".", true, /\.js$/)

let directives = {}
files.keys().forEach((key) => {
  if (key === "./index.js") return
  Object.assign(directives, files(key).default)
})

var MyPlugin = {
  install(Vue) {
    for (let key in directives) {
      Vue.directive(key, directives[key])
    }
  },
}

export default MyPlugin
